import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { clone } from 'lodash';
import { Button, Col, Form, Input, Row, Spin, Divider, Checkbox } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import TeamService from '../../services/team.service';

// import AuthService from '../../services/auth.service';
import WalletService from '../../services/wallet.service';
import { requestError } from '../../helpers/notification';
import { selectTeamList } from '../../redux/slices/app/user.slice';
import { selectUpdatedUserId, selectUpdatingData, setUserBroadcastData } from '../../redux/slices/app/user-broadcast.slice';

const UserWrapper = styled.div`
    max-height: 300px;
    margin-bottom: 20px;
    overflow-y: auto;

    &:last-child {
        border-bottom: none;
    }
`;

const UserRow = styled.div`
    border-bottom: 1px solid #e4e4e4;
    padding: 2px 0 8px 0;

    .toggle-wrapper {
        display: inline-block;
        margin-right: 18px;
        position: relative;
        top: 6px;
    }
`;

const StyledToggle = styled(Toggle)`
    .react-toggle-track {
        background: #ec5b5b;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: #ec5b5b;
    }

    &.react-toggle--checked .react-toggle-track {
        background-color: #19ab27;
    }

    &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: #19ab27;
    }
`;
const UserEditionForm = ({ user, showList }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userCreationBlocked, setUserCreationBlocked] = useState(false);
    const [mailBody, setMailBody] = useState(null);
    const teamList = useSelector(selectTeamList)

    const updatedUserId = useSelector(selectUpdatedUserId);
    const updatingData = useSelector(selectUpdatingData);

    const [teamsList, setTeamsList] = useState([]);
    const [userTeams, setUserTeams] = useState(null);
    const { t } = useTranslation();
    const programInfo = localStorage.getItem('prg') ? JSON.parse(localStorage.getItem('prg')) : null;
    const service = WalletService;

    useEffect(() => {
        const onTeamListChange = async () => {
            // notificationInfo('Mise à jour automatique des données', '')
            const newTeamsList = teamList.filter((team) => !team.single);
            if (newTeamsList.length === teamsList.length) {
                const cloneTeamsList = [...teamsList];
                teamsList.forEach((el, index) => {
                    newTeamsList.forEach((i) => {
                        if (i.id === el.id) {
                            cloneTeamsList[index] = i;
                        }
                    });
                });
                setTeamsList(cloneTeamsList);
            } else {
                setTeamsList(newTeamsList);
            }
        };
        onTeamListChange();
    }, [teamList]);

    useEffect(() => {
        (async () => {
            if (programInfo) {
                setLoading(true);
                const teamsOfUser = await TeamService.getUserTeams(user.userId);
                setUserTeams(teamsOfUser.map((team) => team.id));
                setLoading(false);
            }
            setUserCreationBlocked(false);
            setMailBody(null);
        })();
    }, []);

    const onCancel = () => {
        showList();
    };

    const createUser = async (values) => {
        try {
            await service.createUser(values);
            setLoading(false);
            showList();
        } catch (error) {
            setLoading(false);

            if (error?.response?.data?.type === 'required_pl_admin_operation') {
                setUserCreationBlocked(true);

                let mailBodyText = t('mail_content.hello').concat(',\\n\\n');
                mailBodyText += t('mail_content.need_permission_to_create_the_following_user\n').concat(':\n\n');
                mailBodyText += `-`
                    .concat(t('user_infos.last_name'))
                    .concat(':')
                    .concat(`${values.lastName}`)
                    .concat('\n');
                mailBodyText += `-`
                    .concat(t('user_infos.first_name'))
                    .concat(':')
                    .concat(`${values.firstName}`)
                    .concat('\n');
                mailBodyText += `-`
                    .concat(t('user_infos.phone_number'))
                    .concat(':')
                    .concat(`${values.phoneNumber}`)
                    .concat('\n');
                mailBodyText += `-`
                    .concat(t('user_infos.company'))
                    .concat(':')
                    .concat(`${values.company}`)
                    .concat('\n');

                setMailBody(encodeURIComponent(mailBodyText));
            } else {
                requestError(error, t('error_message.error_on_user_creation'));
            }
        }
    };

    const updateUser = (userId, values) => {
        service
            .updateUser(userId, values)
            .then(() => {
                // AuthService.getSession();
                setLoading(false);
                showList();
            })
            .catch((error) => {
                setLoading(false);

                if (error.response.data && error.response.data.type) {
                    if (error.response.data.type === 'invalid_phone_number') {
                        form.setFields([
                            {
                                name: 'phoneNumber',
                                errors: [t('error_message.invalid_phone_number')],
                            },
                        ]);
                        return;
                    }
                }

                requestError(error, t('error_message.error_while_editing_user'));
            });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        setLoading(true);
        if (Object.keys(user).length) {
            const id = user.userId;
            updateUser(id, values);
        } else {
            createUser(values);
        }
    };

    const entity = clone(user);

    if (userCreationBlocked) {
        return (
            <div className="bg-red-300 border-red-500 rounded p-4">
                <div className="font-bold text-xl mb-4">{t('general.error')}</div>
                <div className="mb-4">{t('error_message.do_not_have_permissions_to_create_user')}</div>
                <div>
                    <a
                        href={`mailto:support@projetlineaire.com?subject=[Gantt Online] Création d'utilisateurs bloqué&body=${mailBody}`}
                        className="block bg-white border-gray-400 text-center p-2 hover:bg-red-600 hover:text-red-200 transition-colors duration-400 rounded-md"
                    >
                        {t('email.send_email_to_pl')}
                    </a>
                </div>
            </div>
        );
    }

    const setUserInTeam = (team, isChecked) => {
        setLoading(true);
        if (isChecked) {
            setUserTeams([...userTeams, team.id]);
            TeamService.addUserToTeam(user.userId, team.id).then(() => setLoading(false));
        } else {
            setUserTeams((oldTeams) => {
                const newVal = oldTeams;
                const indexToDelete = oldTeams.findIndex((tm) => tm === team.id);
                if (indexToDelete > -1) {
                    newVal.splice(indexToDelete, 1);
                }
                return newVal;
            });
            TeamService.removeUserFromTeam(user.userId, team.id).then(() => setLoading(false));
        }
    };

    useEffect(() => {
        const onUpdatedUser = async () => {
            if (updatedUserId !== 0 && user.id === updatedUserId) {
                dispatch(setUserBroadcastData({updatingData: true}));
                const userUpdated = await service.getUser(updatedUserId);
                // updating form
                const updatedData = {
                    firstName: userUpdated.firstName,
                    lastName: userUpdated.lastName,
                    email: userUpdated.email,
                    phoneNumber: userUpdated.phoneNumber,
                    company: userUpdated.company,
                    notes: userUpdated.notes,
                };
                form.setFieldsValue(updatedData);
                dispatch(setUserBroadcastData({updatingData: false}));
            }
        };
        onUpdatedUser();
    }, [updatedUserId]);

    return (
        <Spin spinning={updatingData || loading} size="large">
            <div className="flex justify-between">
                <Form
                    layout="vertical"
                    className={`${programInfo ? 'w-1/2' : 'w-full'}`}
                    form={form}
                    name="user_edit_form"
                    initialValues={entity}
                    onFinish={submitForm}
                >
                    <Form.Item
                        label={t('user_infos.last_name')}
                        name="lastName"
                        rules={[{ required: true, message: t('general.required_field') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('user_infos.first_name')}
                        name="firstName"
                        rules={[{ required: true, message: t('general.required_field') }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t('user_infos.email')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('general.required_field'),
                            },
                            {
                                type: 'email',
                                message: t('error_message.a_valid_email_address_must_be_entered'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('user_infos.phone_number')} name="phoneNumber">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('user_infos.company')} name="company">
                        <Input />
                    </Form.Item>
                    {!programInfo && (
                        <>
                            <Form.Item name="readOnly" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                <Checkbox>{t('user_infos.read_only')}</Checkbox>
                            </Form.Item>
                            <Form.Item name="enable" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                <Checkbox>{t('user_infos.enabled')}</Checkbox>
                            </Form.Item>
                        </>
                    )}

                    <Row>
                        <Col span={24} className="flex justify-between">
                            <Button
                                type="secondary"
                                className="mr-2 flex items-center"
                                onClick={onCancel}
                                icon={<ArrowLeftOutlined />}
                            >
                                {t('general.back_to_list')}
                            </Button>
                            <Button loading={loading} type="primary" htmlType="submit">
                                {t('general.save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {programInfo && userTeams && (
                    <>
                        <Divider type="vertical" style={{ height: 'inherit' }} />
                        <div style={{ width: '48%' }}>
                            <label>{t('user_list.user_teams')}</label>
                            <UserWrapper>
                                {teamsList.map((team) => (
                                    <UserRow key={team.id}>
                                        <div className="toggle-wrapper">
                                            <StyledToggle
                                                defaultChecked={userTeams.indexOf(team.id) !== -1}
                                                onChange={(event) => {
                                                    setUserInTeam(team, event.target.checked);
                                                }}
                                            />
                                        </div>
                                        {team?.name}
                                    </UserRow>
                                ))}
                            </UserWrapper>
                        </div>
                    </>
                )}
            </div>
        </Spin>
    );
};

UserEditionForm.propTypes = {
    user: PropTypes.object.isRequired,
    showList: PropTypes.func.isRequired,
};

export default UserEditionForm;
