import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Table, Spin, Input } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../services/user.service';
import WalletService from '../../services/wallet.service';
import DeleteButton from '../common/tables/DeleteButton';
import EditButton from '../common/tables/EditButton';
import LoadingSpin from '../utils/LoadingSpin';
import { requestError } from '../../helpers/notification';
import { searchData } from '../../helpers/array-filter';
import UserDeleteButton from "../common/tables/UserDeleteButton";
import { selectUpdatedUserId, selectUpdatingData, setUserBroadcastData } from '../../redux/slices/app/user-broadcast.slice';

const { Column } = Table;
const { Search } = Input;

const ActionsColumn = styled(Column)`
    width: 100px;
`;

const UserList = ({ addUser, editUser, removeUser }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [usersList, setUsersList] = useState([]);
    const [rawUserList, setRawUserList] = useState([]);
    const updatedUserId = useSelector(selectUpdatedUserId);
    const updatingData = useSelector(selectUpdatingData);
    const programInfo = localStorage.getItem('prg') ? JSON.parse(localStorage.getItem('prg')) : null;
    const service = WalletService;
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (programInfo) {
                try {
                    const users = await UserService.listUsers();
                    setLoading(false);
                    setUsersList(users);
                    setRawUserList(users);
                } catch (error) {
                    setLoading(false);
                    setUsersList([]);
                    setRawUserList([]);
                    requestError(error, t('error_message.retrieving_users_error'));
                }
            }
            if (!programInfo) {
                try {
                    const users = (await service.listUsers()).map((u) => ({
                        ...u,
                    }));
                    setLoading(false);
                    setUsersList(users);
                    setRawUserList(users);
                } catch (error) {
                    setLoading(false);
                    setUsersList([]);
                    setRawUserList([]);
                    requestError(error, t('error_message.retrieving_users_error'));
                }
            }
        })();
    }, []);

    useEffect(() => {
        const onUpdatedUser = async () => {
            if (updatedUserId !== 0 && rawUserList.length > 0) {
                dispatch(setUserBroadcastData({updatingData: true}));
                // search if updatedUser is in list
                const indexUser = usersList.findIndex((i) => i.id === updatedUserId);
                const newUserList = [...rawUserList];
                if (indexUser !== -1) {
                    const userUpdated = await service.getUser(updatedUserId);
                    if (userUpdated.deleted === false) {
                        newUserList[indexUser] = userUpdated;
                        setRawUserList(newUserList);
                    } else {
                        newUserList.splice(indexUser, 1);
                        setRawUserList(newUserList);
                    }
                } else {
                    const newUser = await service.getUser(updatedUserId);
                    newUserList.push(newUser);
                    setRawUserList(newUserList);
                }
                dispatch(setUserBroadcastData({updatingData: false}));
            }
        };
        onUpdatedUser();
    }, [updatedUserId]);

    if (loading) {
        return <LoadingSpin />;
    }

    const handleSearch = (e) => {
        const filteredResult = searchData(
            rawUserList.map(({ userId, firstName, lastName, email }) => ({ firstName, lastName, email, userId })),
            e.target.value
        );
        setUsersList(filteredResult.map(({userId}) => ({
            ...rawUserList.find(i => i.userId === userId)
        })));
    };

    return (
        <Spin spinning={updatingData} size="large">
            <div className="flex mb-5 w-full justify-between">
                <div className="flex">
                    <Search
                        placeholder={t('general.search')}
                        onChange={handleSearch}
                        allowClear
                        style={{ width: 400 }}
                        className="mr-12"
                    />
                </div>
                {!programInfo && (
                    <Button onClick={addUser} type="primary" style={{ marginBottom: 16 }}>
                        {t('user_list.add_new_user')}
                    </Button>
                )}
            </div>

            <Table dataSource={usersList} pagination={{showTotal: (total) => `${t('general.total')}: ${total}`}} rowKey="userId">
                <Column
                    title={t('user_infos.last_name')}
                    dataIndex="lastName"
                    key="lastName"
                    sortDirections={['ascend', 'descend']}
                    sorter={(a, b) => (a.lastName ?? '').localeCompare(b.lastName ?? '')}
                />
                <Column
                    title={t('user_infos.first_name')}
                    dataIndex="firstName"
                    key="firstName"
                    sortDirections={['ascend', 'descend']}
                    sorter={(a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? '')}
                />
                <Column
                    title={t('user_infos.email')}
                    dataIndex="email"
                    key="email"
                    sortDirections={['ascend', 'descend']}
                    sorter={(a, b) => a.email.localeCompare(b.email)}
                />
                {/* <Column title="Rôle" dataIndex="role" key="role" /> */}
                <ActionsColumn
                    title=""
                    key="actions"
                    width={100}
                    render={(text, record) => (
                        <>
                            <EditButton onClick={() => editUser(record)} className="mr-2" />
                            {programInfo?  <UserDeleteButton onClick={() => removeUser(record)} />
                                        :  <DeleteButton onClick={() => removeUser(record)} />
                            }
                        </>
                    )}
                />
            </Table>
        </Spin>
    );
};

UserList.propTypes = {
    addUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    removeUser: PropTypes.func.isRequired,
};

export default UserList;
