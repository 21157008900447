import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import TeamService from '../../services/team.service';
import UserService from '../../services/user.service';
import { requestError } from '../../helpers/notification';
import {searchData} from '../../helpers/array-filter';
import { selectUpdatedTeam } from '../../redux/slices/app/user-broadcast.slice';

const {Search} = Input;

const UserWrapper = styled.div`
    max-height: 300px;
    margin-bottom: 20px;
    overflow-y: scroll;

    &:last-child {
        border-bottom: none;
    }
`;

const UserRow = styled.div`
    border-bottom: 1px solid #e4e4e4;
    padding: 2px 0 8px 0;

    .toggle-wrapper {
        display: inline-block;
        margin-right: 18px;
        position: relative;
        top: 6px;
    }
`;

const StyledToggle = styled(Toggle)`
    .react-toggle-track {
        background: #ec5b5b;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: #ec5b5b;
    }

    &.react-toggle--checked .react-toggle-track {
        background-color: #19ab27;
    }

    &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: #19ab27;
    }
`;
// TODO @TRAD
const TeamEditionForm = ({ team, showList }) => {
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [currentTeam, setCurrentTeam] = useState(team);
    const [loading, setLoading] = useState(false);
    const updatedTeam = useSelector(selectUpdatedTeam);
    const {t} = useTranslation()

    useEffect(() => {
        (async () => {
            const response = await UserService.listUsers();
            
            const usersList = response.map((user) => ({
                id: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
            }));
            
            setAllUsers(usersList);
            setUsers(usersList);
        })();
    }, []);

    const handleSearch = (e) => {
        const filteredResult = searchData(
            allUsers.map(({id, firstName, lastName }) => ({ id,firstName, lastName })),
            e.target.value
        );
        setUsers(filteredResult);
    };

    useEffect(() => {
        if (updatedTeam && +updatedTeam.id === team.id) {
            console.log('___________________updatedTeam',updatedTeam);
            if (updatedTeam.name) {
                form.setFieldsValue(updatedTeam);
                setCurrentTeam(updatedTeam);
            } else {
                showList();
            }
        }
    }, [updatedTeam]);

    const onCancel = () => {
        showList();
    };

    const validationError = (error) => {
        if (error?.response?.status === 422 && error?.response?.data?.field === 'Name') {
            form.setFields([
                {
                    name: 'name',
                    errors: ['Le nom doit être unique'],
                },
            ]);
        } else {
            requestError(error, 'Erreur lors de la création du métier');
        }
        setLoading(false);
    };

    const createTeam = async (values) => {
        try {
            await TeamService.createTeam(values);
            setLoading(false);
            showList();
        } catch (error) {
            validationError(error);
        }
    };

    const updateTeam = async (teamId, values) => {
        try {
            await TeamService.updateTeam(teamId, values);
            setLoading(false);
            showList();
        } catch (error) {
            validationError(error);
        }
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        setLoading(true);

        if (team.id) {
            updateTeam(team.id, values);
        } else {
            createTeam(values);
        }
    };

    const setUserInTeam = async (user, isChecked) => {
        setLoading(true);
        if (isChecked) {
            await TeamService.addUserToTeam(user.id, team.id);
        } else {
            await TeamService.removeUserFromTeam(user.id, team.id);
        }
        setLoading(false);
    };

    return (
        <Form form={form} layout="vertical" name="team_edit_form" initialValues={team} onFinish={submitForm}>
            <Form.Item label="Nom" name="name" rules={[{ required: true, message: 'Ce champ est requis' }]}>
                <Input />
            </Form.Item>

            {currentTeam.id ? (
                <>
                    <Search
                        placeholder={t('general.search')}
                        onChange={handleSearch}
                        allowClear
                        style={{ width: 400 }}
                        className="mr-12"
                    />
                    <UserWrapper>
                        {users.map((user) => (
                            <UserRow key={user.id}>
                                <div className="toggle-wrapper">
                                    <StyledToggle
                                        checked={currentTeam?.userIds?.indexOf(user.id) > -1}
                                        onChange={(event) => {
                                            setUserInTeam(user, event.target.checked);
                                        }}
                                    />
                                </div>
                                {user?.lastName?.toUpperCase()} {user?.firstName}
                            </UserRow>
                        ))}
                    </UserWrapper>
                </>
            ) : (
                ''
            )}

            <Row>
                <Col span={24} className="flex justify-between">
                    <Button
                        type="secondary"
                        className="mr-2 flex items-center"
                        onClick={onCancel}
                        icon={<ArrowLeftOutlined />}
                    >
                        Retourner à la liste
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

TeamEditionForm.propTypes = {
    team: PropTypes.object.isRequired,
    showList: PropTypes.func.isRequired,
};

export default TeamEditionForm;
